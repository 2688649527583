<template>
    <div class="clue-search-page">
        <!-- 线索组件 -->
        <div v-if="searchList.length > 0" class="clue">
            <div>
                查询结果：员工中找到
                <span style="color:#4086EC">{{ total }}</span>条
            </div>
        </div>

        <!-- 列表区域 -->
        <div
            v-if="total > 0"
            v-infinite-scroll="load"
            infinite-scroll-disabled="disabled"
            style="overflow: scroll;height: 90%;"
        >
            <div v-for="(item, index) in searchList" :key="index">
                <div class="flex-sb clue-item">
                    <div class="item-info flex-sb">
                        <div class="flex-sb left-items">
                            姓名：
                            <div v-html="item.name" />手机号：
                            <div
                                style="line-height: 23px;"
                            >{{ common.hidePhoneNumber( item.mobileNumber ) }}</div>角色：
                            <div v-html="item.role" />
                        </div>
                        <div class="right-info">
                            所属部门：
                            <div v-html="item.department" />上级部门：
                            <div v-html="item.parentDepartment" />
                        </div>
                    </div>
                    <div
                        style="text-align:right;border-left:1px solid #ccc;width: 100px;text-align: center;"
                    >
                        <el-button
                            v-if="item.havePermission == 1"
                            type="primary"
                            size="small"
                            @click="toDetail(item)"
                        >前往</el-button>
                    </div>
                </div>
            </div>
            <p v-if="loading" class="clue" style="text-align:center;margin: 10px 0;">
                <span>加载中</span>
            </p>
            <p v-if="noMore" class="clue" style="text-align:center;margin: 10px 0;">- 没有更多了-</p>
        </div>
        <div v-else class="clue">
            <div>查询结果：员工中没有找到相关内容</div>
        </div>
    </div>
</template>

<script>
import { allSearch } from '@/api/all_search';
export default {
    data() {
        return {
            status: {
                keyword: '',
                category: 4,
                pageNo: 1,
                pageSize: 10,
            },
            // search: "",
            searchList: [],
            total: '',
            loading: false,
        };
    },
    computed: {
        noMore() {
            //当起始页数大于总页数时停止加载
            return this.status.pageSize >= this.total;
        },
        disabled() {
            return this.loading || this.noMore;
        },
    },
    created() {
        // this.getSearchData();
    },
    methods: {
        // 传入搜索值
        init(val) {
            this.status.keyword = val;
            if (val) {
                this.getSearchData();
            } else {
                this.searchList = [];
            }
        },
        // 搜索高亮
        signKeyword(val) {
            if (val) {
                let keyword = this.status.keyword; //搜索的值
                if (val.indexOf(keyword) !== -1) {
                    return val.replace(
                        keyword,
                        `<font color='#f00'>${keyword}</font>`
                    );
                } else {
                    return val;
                }
            }
        },
        // 详情 - 未做
        toDetail(item) {
            console.log(item);
            this.$router.push({
                name: 'personnel',
                params: { name: item.department },
            });
        },
        // 异步获取数据高亮显示
        getSearchData() {
            allSearch(this.status).then((res) => {
                console.log(res.data);
                this.total = res.data.total;
                let data = res.data.list;
                let records = data;
                if (records != undefined) {
                    records.map((item) => {
                        item.name = this.signKeyword(item.name);
                        item.mobileNumber = this.signKeyword(item.mobileNumber);
                    });
                } else {
                    records = [];
                }
                this.searchList = records;
                this.loading = false;
            });
        },
        load() {
            //滑到底部时进行加载
            this.loading = true;
            setTimeout(() => {
                this.status.pageSize += 10; //页数+1
                if (this.status.keyword == '') {
                    this.searchList = [];
                    return;
                } else {
                    this.getSearchData(); //调用接口，此时页数+1，查询下一页数据
                }
            }, 2000);
        },
    },
};
</script>

<style lang="less" scoped>
.clue-search-page {
    // min-width: 1200px;
    // overflow: scroll;
    height: calc(100vh - 210px);
    .flex-sb {
        display: flex;
        justify-content: space-between;
    }
    .clue-item {
        box-shadow: 0px 0px 14px #ccc;
        // margin: 15px;
        margin-right: 15px;
        margin-bottom: 15px;
        margin-top: 15px;
        margin-left: 2px;
        padding: 10px;
        border-radius: 5px;
        font-size: 14px;

        .item-info {
            display: flex;
            align-items: center;
            width: 92%;
            margin-left: 15px;
            img {
                width: 23px;
                height: 23px;
            }
            .left-items {
                font-size: 16px;
                div {
                    margin-right: 20px;
                }
            }
            .right-info {
                display: flex;
                align-items: center;
                color: #7f7f7f;
                font-size: 14px;
                div {
                    margin-right: 20px;
                }
            }
        }
    }
    .clue {
        font-size: 14px;
        color: #aaaaaa;
    }
}
</style>
