<template>
    <div class="clue-keyword-page">
        <div style="height:100%">
            <!-- 线索组件 -->
            <div v-if="total > 0" class="clue">
                <div>
                    查询结果：客户中找到
                    <span style="color:#4086EC">{{ total }}</span>条
                </div>
            </div>

            <!-- 列表区域 -->
            <div
                v-if="total > 0"
                v-infinite-scroll="load"
                infinite-scroll-disabled="disabled"
                style="overflow:scroll;height: 90%;"
            >
                <div v-for="(item, index) in searchList" :key="index">
                    <div class="flex-sb clue-item">
                        <div style="flex:2 1 0%;display:flex">
                            <!--  -->
                            <div>
                                <div style="display:flex;align-items: center;">
                                    <img src="@/assets/searchPage/phone.png" alt />
                                    <div
                                        style="font-size:20px;color:#4086EC"
                                        v-html="item.mobileNumber"
                                    />
                                </div>
                                <div style="text-align: center;margin-top:5px">
                                    (ID:
                                    <span v-html="item.id" />)
                                </div>
                            </div>

                            <div style="margin-left: 15%;line-height: 26.5px;">
                                <div style="display: flex;align-items: center;">
                                    姓名：
                                    <div style="margin-right: 10px;" v-html="item.name" />资金需求：
                                    <div v-html="item.fundNeeds" />
                                </div>
                                <div style="display: flex;align-items: center;">
                                    资质：
                                    <div v-html="item.quality" />
                                </div>
                            </div>
                        </div>
                        <div class="remark" style="flex:2">
                            <span v-html="item.followContent" />
                        </div>
                        <div
                            style="text-align:right;border-left:1px solid #ccc;width: 100px;text-align: center;"
                        >
                            <el-button
                                v-if="item.havePermission == 1"
                                type="primary"
                                size="mini"
                                @click="toDetail(item)"
                            >查看</el-button>
                        </div>
                    </div>
                </div>
                <p v-if="loading" class="clue" style="text-align:center;margin: 10px 0;">
                    <span>加载中</span>
                </p>
                <p v-if="noMore" class="clue" style="text-align:center;margin: 10px 0;">- 没有更多了-</p>
            </div>
            <div v-else class="clue">
                <div>查询结果：客户中没有找到相关内容</div>
            </div>
        </div>
    </div>
</template>

<script>
import { allSearch } from '@/api/all_search';
export default {
    data() {
        return {
            status: {
                keyword: '',
                category: 2,
                pageNo: 1,
                pageSize: 10,
            },
            searchList: [],
            loading: false,
            total: '',
        };
    },
    computed: {
        noMore() {
            //当起始页数大于总页数时停止加载
            return this.status.pageSize >= this.total;
        },
        disabled() {
            return this.loading || this.noMore;
        },
    },
    created() {
        // this.getSearchData();
    },
    methods: {
        // 传入搜索值
        init(val) {
            console.log(val);
            this.status.keyword = val;
            if (val) {
                this.getSearchData();
            } else {
                this.searchList = [];
            }
        },
        // 搜索高亮
        signKeyword(val) {
            if (val) {
                let keyword = this.status.keyword; //搜索的值
                console.log('val', val);
                console.log('this.status.keyword', this.status.keyword);
                console.log(
                    'val.indexOf(keyword) !== -1',
                    val.indexOf(keyword) !== -1
                );
                if (val.indexOf(keyword) !== -1) {
                    return val.replace(
                        keyword,
                        `<font color='#f00'>${keyword}</font>`
                    );
                } else {
                    return val;
                }
            }
        },
        // 详情 - 未做
        toDetail(item) {
            console.log(item);
            this.$emit('clientDetail', item);
        },
        // 异步获取数据高亮显示
        getSearchData() {
            allSearch(this.status).then((res) => {
                let data = res.data.list;

                this.total = res.data.total;
                let records = data;

                if (records != undefined) {
                    records.map((item) => {
                        console.log(item);
                        item.name = this.signKeyword(item.name);
                        item.mobileNumber = this.signKeyword(item.mobileNumber);
                        let Str = item.id.toString();
                        item.id = this.signKeyword(Str);
                        item.followContent = this.signKeyword(
                            item.followContent
                        );
                    });
                } else {
                    records = [];
                }
                this.searchList = records;
                this.loading = false;
            });
        },
        load() {
            //滑到底部时进行加载
            this.loading = true;
            setTimeout(() => {
                this.status.pageSize += 10; //页数+1
                if (this.status.keyword == '') {
                    this.searchList = [];
                    return;
                } else {
                    this.getSearchData(); //调用接口，此时页数+1，查询下一页数据
                }
            }, 2000);
        },
    },
};
</script>

<style lang="less" scoped>
.clue-keyword-page {
    // min-width: 1200px;
    // overflow: scroll;
    height: calc(100vh - 210px);
    .flex-sb {
        display: flex;
        justify-content: space-between;
    }
    .clue-item {
        box-shadow: 0px 0px 14px #ccc;
        // margin: 15px;
        margin-right: 15px;
        margin-bottom: 15px;
        margin-top: 15px;
        margin-left: 2px;
        padding: 10px;
        border-radius: 5px;
        font-size: 14px;
        align-items: center;

        .item-info {
            display: flex;
            align-items: center;
            width: 92%;
            margin-left: 15px;
            img {
                width: 23px;
                height: 23px;
            }
            .left-items {
                font-size: 16px;
                div {
                    margin-right: 20px;
                }
            }
            .right-info {
                display: flex;
                align-items: center;
                color: #7f7f7f;
                font-size: 14px;
                div {
                    margin-right: 20px;
                }
            }
        }
    }
    .clue {
        font-size: 14px;
        color: #aaaaaa;
    }
    .remark {
        margin-top: 10px;
        margin-bottom: 10px;
        border-left: 1px solid #ccc;
        padding-left: 10px;
        padding-top: 7px;
        padding-bottom: 7px;
        color: #7f7f7f;
    }
}
</style>
