<template>
    <div class="clue-search-page">
        <!-- 线索组件 -->
        <div v-if="total > 0" class="clue">
            <div>
                查询结果：线索中找到
                <span style="color:#4086EC">{{ total }}</span>条
            </div>
        </div>

        <!-- 列表区域 -->
        <div
            v-if="total > 0"
            v-infinite-scroll="load"
            infinite-scroll-disabled="disabled"
            style="overflow: scroll;height: 90%;"
        >
            <div v-for="(item, index) in searchList" :key="index">
                <div class="flex-sb clue-item">
                    <div class="item-info flex-sb">
                        <div class="flex-sb">
                            <img src="@/assets/searchPage/phone.png" alt />
                            <div style="font-size:20px;color:#4086EC" v-html="item.mobileNumber" />
                        </div>
                        <div class="flex-sb items">
                            姓名：
                            <div v-html="item.name" />资金需求：
                            <div v-html="item.fundNeeds" />资质：
                            <div v-html="item.quality" />
                        </div>
                    </div>
                    <div
                        style="text-align:right;border-left:1px solid #ccc;width: 100px;text-align: center;"
                    >
                        <el-button
                            v-if="item.havePermission == 1"
                            type="primary"
                            size="mini"
                            @click="toDetail(item)"
                        >查看</el-button>
                    </div>
                </div>
            </div>
            <div v-if="loading" class="clue" style="text-align:center;margin: 10px 0;">
                <span>加载中</span>
            </div>
            <p v-if="noMore" class="clue" style="text-align:center;margin: 10px 0;">- 没有更多了-</p>
        </div>
        <div v-else class="clue">
            <div>查询结果：线索中没有找到相关内容</div>
        </div>
    </div>
</template>

<script>
import { allSearch } from '@/api/all_search';
export default {
    data() {
        return {
            status: {
                keyword: '',
                category: 1,
                pageNo: 1,
                pageSize: 10,
            },
            searchList: [],
            loading: false,
            total: '',
        };
    },
    computed: {
        noMore() {
            //当起始页数大于总页数时停止加载
            return this.status.pageSize >= this.total;
        },
        disabled() {
            return this.loading || this.noMore;
        },
    },
    created() {
        // this.getSearchData();
    },
    methods: {
        init(val) {
            this.status.keyword = val;
            // this.getSearchData();
            if (val) {
                this.getSearchData();
            } else {
                this.searchList = [];
            }
        },
        signKeyword(val) {
            if (val) {
                // console.log(this.status.keyword);
                let keyword = this.status.keyword; //搜索的值
                // console.log(keyword, this.search);
                if (val.indexOf(keyword) !== -1) {
                    return val.replace(
                        keyword,
                        `<font color='#f00'>${keyword}</font>`
                    );
                } else {
                    return val;
                }
            }
        },

        toDetail(item) {
            this.$emit('detailClue', item);
        },
        getSearchData() {
            allSearch(this.status).then((res) => {
                let data = res.data.list;
                this.total = res.data.total;
                let records = data;
                if (records != undefined) {
                    records.map((item) => {
                        item.name = this.signKeyword(item.name);
                        item.mobileNumber = this.signKeyword(item.mobileNumber);
                    });
                } else {
                    records = [];
                }
                this.searchList = records;
                this.loading = false;
            });
        },
        load() {
            //滑到底部时进行加载
            this.loading = true;
            setTimeout(() => {
                this.status.pageSize += 10; //页数+1
                if (this.status.keyword == '') {
                    this.searchList = [];
                    return;
                } else {
                    this.getSearchData(); //调用接口，此时页数+1，查询下一页数据
                }
            }, 2000);
        },
    },
};
</script>

<style lang="less" scoped>
.clue-search-page {
    // min-width: 1200px;
    // overflow: scroll;
    height: calc(100vh - 210px);
    .flex-sb {
        display: flex;
        justify-content: space-between;
    }
    .clue-item {
        box-shadow: 0px 0px 14px #ccc;
        // margin: 15px;
        margin-right: 15px;
        margin-bottom: 15px;
        margin-top: 15px;
        margin-left: 2px;
        padding: 10px;
        border-radius: 5px;
        font-size: 14px;

        .item-info {
            display: flex;
            align-items: center;
            margin-left: 15px;
            img {
                width: 23px;
                height: 23px;
            }
            .items {
                margin-left: 20vh;
                div {
                    margin-right: 10px;
                }
            }
        }
    }
    .clue {
        font-size: 14px;
        color: #aaaaaa;
    }
}
</style>
