<template>
    <div style="height:100%">
        <!-- 全局搜索页面 -->
        <div v-show="!showClientFollow && !showClueFollow" class="search-page">
            <!-- 查询input -->
            <div class="search-input">
                <el-input
                    v-model="searchInfo"
                    style="width:400px"
                    placeholder="请输入搜索内容"
                    clearable
                    @keyup.enter.native="getData"
                    @clear="clearSearch"
                    @change="clearSearch"
                />
                <el-button type="primary" @click="getData">查询</el-button>
            </div>

            <!-- tabs的切换 -->
            <div class="tabs-info">
                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane label="线索" name="0">
                        <div>
                            <div v-show="firstEnter" class="clue">按回车发起检索</div>
                            <cule v-show="!firstEnter" ref="clue" @detailClue="detailClue" />
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="客户" name="1">
                        <div>
                            <div v-show="firstEnter" class="clue">按回车发起检索</div>
                            <client v-show="!firstEnter" ref="client" @clientDetail="clientDetail" />
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="部门" name="2">
                        <div>
                            <div v-show="firstEnter" class="clue">按回车发起检索</div>
                            <department v-show="!firstEnter" ref="department" />
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="员工" name="3">
                        <div>
                            <div v-show="firstEnter" class="clue">按回车发起检索</div>
                            <staff v-show="!firstEnter" ref="staff" />
                        </div>
                    </el-tab-pane>
                </el-tabs>
                <!-- 线索详情弹窗 -->
                <detail-dialog
                    ref="showDialog"
                    :show.sync="showDialog"
                    @refresh="refreshClue"
                    @followPage="followPage"
                    @cilentFolloePage="cilentFolloePage"
                />
                <!-- 客户详情弹窗 -->
                <client-dialog
                    ref="clientDialog"
                    @refresh="refreshClient"
                    @cilentFolloePage="cilentFolloePage"
                />
            </div>

            <!-- 跟进客户组件 -->

            <!-- 跟进线索组件 -->
            <!-- 
      v-show="showFollow"
      @closeFollow="closeFollow"
            @refresh="refresh"-->
        </div>
        <client-follow
            v-if="showClientFollow"
            ref="clientFollowPage"
            @refresh="refreshClient"
            @closeFollow="closeFollow"
        />
        <clue-follow v-if="showClueFollow" ref="clueFollow" @closeFollow="closeClueFollow" />
    </div>
</template>

<script>
import cule from './components/clue';
import client from './components/client';
import department from './components/department';
import staff from './components/staff';
import detailDialog from '../clue/recordComponents/detail-dialog';
import clientDialog from '../client/components/customerInfo';
import clientFollow from '../client/follow';
import clueFollow from '../clue/follow';
export default {
    data() {
        return {
            searchInfo: '',
            activeName: '0',
            firstEnter: true,
            showDialog: false,
            showClientFollow: false,
            showClueFollow: false,
        };
    },
    components: {
        cule,
        client,
        department,
        staff,
        detailDialog,
        clientDialog,
        clientFollow,
        clueFollow,
    },
    methods: {
        handleClick() {
            console.log('searchInfo', this.searchInfo);
            console.log(this.activeName);
            sessionStorage.setItem('activeName', this.activeName);
            // this.searchInfo = ''
            if (this.searchInfo) this.getData();
        },
        getData() {
            if (this.searchInfo == '') {
                this.firstEnter = true;
            } else {
                this.firstEnter = false;
            }
            this.$nextTick(() => {
                if (this.activeName == 0) {
                    this.$refs.clue.init(this.searchInfo);
                }
                if (this.activeName == 1) {
                    this.$refs.client.init(this.searchInfo);
                }
                if (this.activeName == 2) {
                    this.$refs.department.init(this.searchInfo);
                }
                if (this.activeName == 3) {
                    this.$refs.staff.init(this.searchInfo);
                }
            });
        },
        // 清除搜索做的事
        clearSearch() {
            if (this.searchInfo == '') {
                this.firstEnter = true;
            } else {
                this.firstEnter = false;
            }
            this.getData();
        },
        // 线索详情
        detailClue(row) {
            console.log('详情', row);
            this.$nextTick(() => {
                this.$refs.showDialog.init(row.id);
            });
        },
        // 刷新线索列表
        refreshClue() {
            this.$refs.clue.init(this.searchInfo);
        },
        // 显示线索跟进页面
        followPage(val) {
            this.showClueFollow = true;
            this.$nextTick(() => {
                this.$refs.clueFollow.init(val);
            });
        },
        // 关闭线索跟进
        closeClueFollow() {
            this.showClueFollow = false;
        },
        // 跳客户详情
        clientDetail(row) {
            if (row.id.includes('font')) {
                let str = row.id.split('>');
                let idStr = [];
                str.forEach((item) => {
                    let index = item.indexOf('<');
                    if (index > 0) {
                        idStr.push(item.substring(0, index));
                    } else {
                        if (/^\d+$/.test(item)) {
                            idStr.push(item);
                        }
                    }
                });
                let clientId = idStr.join('');
                this.$refs.clientDialog.init(clientId, true);
            } else {
                this.$refs.clientDialog.init(row.id, true);
            }
        },
        // 刷新客户列表
        refreshClient() {
            this.$refs.client.init(this.searchInfo);
            this.closeFollow();
        },
        cilentFolloePage(val) {
            this.showClientFollow = true;
            this.$nextTick(() => {
                this.$refs.clientDialog.handleDialogClose();
                this.$refs.clientFollowPage.init(val);
            });
        },
        // 客户跟进返回
        closeFollow() {
            this.showClientFollow = false;
        },
    },
};
</script>
<style lang="less" scoped>
.search-page {
    width: 100%;
    height: calc(100vh - 60px);
    overflow: hidden;
    background-color: #fff;
    padding: 24px;
    .search-input {
        display: flex;
        justify-content: left;
        .el-input {
            min-width: 430px;
            /deep/ .el-input__inner {
                border-radius: 4px 0px 0px 4px;
            }
        }
        .el-button {
            width: 85px;
            border-radius: 0px 4px 4px 0px;
        }
    }
    //   /deep/ .el-tabs__nav-wrap::after{
    //       content: none;
    //   }
    .tabs-info {
        /deep/ .el-tabs__active-bar {
            height: 5px;
        }
        /deep/ .el-tabs__item {
            color: #aaaaaa;
        }
        /deep/ .is-active {
            color: #555555;
        }
    }
    .clue {
        font-size: 14px;
        color: #aaaaaa;
    }
    /deep/ .el-tabs__nav-wrap::after {
        content: none;
    }
}
</style>
