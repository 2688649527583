import request from './request.js';
import public_request from './public_request';
// 线索列表搜索
export function allSearch(data) {
    return request({
        url: '/search',
        method: 'GET',
        params: public_request(data),
    });
}
